'use strict';

/**
 * Tooltip Class
 */
export default class FastFundToolTip {
  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   icon - controlling icon Element
   */
  constructor(icon) {
    // members
    this.icon         = icon;                         // controlling icon Element
    this.icon.tooltip = this;                         // reference to this obj
    this.timeout      = null;                         // setTimeout reference
    this.isOpen       = false;                        // open flag
    this.closeDelay   = 500;                          // tooltip close delay
    this.tooltip      = null;                         // tooltip element

    // bind 'this' to the method so 'this' always refers to our instance
    this.open        = this.open.bind(this);
    this.close       = this.close.bind(this);
    this._addTimer   = this._addTimer.bind(this);
    this._clearTimer = this._clearTimer.bind(this);
    this._close      = this._close.bind(this);
    this.toggle      = this.toggle.bind(this);

    // initialize events
    document.body.addEventListener('keydown', event => this._onBodyEvent(event));
    this.icon.addEventListener('click', this.toggle);
    this.icon.addEventListener('mouseover', this.open);
    this.icon.addEventListener('mouseout', this.close);

    ff.updateObjects(icon, this);
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Handle an event from the document body for this object
   *
   * @param   {Event}   event - Event object
   */
  _onBodyEvent(event) {
    switch (event.type) {
      case 'keydown':
        if (event.key == 'Escape') this.close(true);
        break;
    }
  }

  /**
   * Set the tooltip's screen position and open
   */
  _open() {
    this._clearTimer();
    const content = this.icon.dataset.tooltip.trim();

    if (!this.isOpen && !ff.isEmpty(content)) {
      const self = this;

      this.tooltip             = document.createElement('div');
      this.tooltip.innerHTML   = content;
      this.tooltip.className   = 'ff-tooltip-content flash-info ff-info';
      this.tooltip.onmouseover = ()=>{ self.open() };
      this.tooltip.onmouseout  = ()=>{ self.close() };

      Object.assign(this.tooltip.style, {
        position: 'absolute',
        top:      0,
        left:     0,
        width:    'max-content',
        zIndex:   ff.GLOBALS.zIndex++
      });

      document.body.appendChild(this.tooltip);
      if (this.icon.dataset.width) this.tooltip.style.width = this.icon.dataset.width;

      let placement = this.icon.dataset.bottom ? 'bottom' : 'top';
      if (this.icon.dataset.right) placement = 'right';

      // position with Floating-UI
      FloatingUI.computePosition(this.icon, this.tooltip, {
        placement:  placement,
        middleware: [FloatingUI.offset(2), FloatingUI.flip(), FloatingUI.shift({padding: 5})],
      }).then(({x, y}) => {
        Object.assign(this.tooltip.style, {left: `${x}px`, top: `${y}px`});
      });

      /* ----- old manual positioning -----
      let iconPos = ff.getPosition(this.icon);
      if (this.icon.dataset.under) this.tooltip.style.top = `${iconPos.bottom+2}px`; // position below
      else this.tooltip.style.top = `${iconPos.top-this.tooltip.offsetHeight-2}px`;  // position above

      if (this.icon.dataset.right) {
        // position to the right
        this.tooltip.style.top  = `${(iconPos.top-this.tooltip.offsetHeight/2)+3}px`;
        this.tooltip.style.left = `${iconPos.left+20}px`;
      }
      else {
        // center the tooltip over or under its icon
        this.tooltip.style.left = `${iconPos.left-(this.tooltip.offsetWidth/2)+10}px`;
      }

      // check for body overflow
      let ttPos       = ff.getPosition(this.tooltip);
      let windowWidth = window.innerWidth || document.documentElement.clientWidth;

      if (ttPos.right >= windowWidth-1) {
        // tt overflows the window, place it left of the parent
        let ttLeft = iconPos.right - ttPos.width;
        if (ttLeft < 10) ttLeft = 20;                    // tt was outside the left side
        this.tooltip.style.left = `${ttLeft}px`;
      }
      */

      this.isOpen = true;
    }
  }

  /**
   * Add a close timer for mouseleave events
   */
  _addTimer() {
    this._clearTimer();
    this.timeout = setTimeout(this._close, this.closeDelay);
  }

  /**
   * Clear the close timer
   */
  _clearTimer() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  /**
   * Close/hide the tooltip
   */
  _close() {
    this._clearTimer();
    if (this.tooltip) this.tooltip.remove();
    this.isOpen = false;
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Close (hide) the tooltip
   *
   * @param   {Boolean} force - Force closed without a timeout
   */
  close(force) {
    if (force === true) this._close();
    else this._addTimer();
  }

  /**
   * Destroy the tooltip element
   */
  destroy() {
    if (this.tooltip) this.tooltip.remove();
    this.icon.tooltip = null;
    document.body.removeEventListener('keydown', event => this._onBodyEvent(event));
  }

  /**
   * Open the tooltip
   */
  open() {
    this._open();
  }

  /**
   * Toggle the tooltip
   */
  toggle() {
    if (this.isOpen) this._close();
    else this._open();
  }

}
