// ----------------------------------------------------------------------------
// Event Handlers
// ----------------------------------------------------------------------------

/**
 * Parent init function
 */
export async function initFastFund() {
  await ff.initBodyEventHandlers();
  await ff.initContent();
}

/**
 * Initialize Body Event Handlers
 */
export async function initBodyEventHandlers() {
  ajaxHandlers();
  clickHandlers();
  keyHandlers();
  windowHandlers();
}

/**
 * Initialize page content
 */
export async function initContent() {
  const focus = await ff.initForms();       // forms must come first to init any objects
  await ff.initObjects();
  await ff.initScrollables();

  // This event is dispatched after initial page load and for every AJAX update
  document.dispatchEvent(ff.EVENTS.contentLoaded);
  if (focus) ff.focusForm(focus);
}

/**
 * Initialize an event/mutation observer for the disable attribute.
 * Target objects must respond to enable() and disable() calls.
 *
 * @param   {Element}  el     - Element to observe
 * @param   {Object}   object - Parent FastFund object (eg. FastFundDate, FastFundAccount)
 */
export function addMutationObserver(el, object) {
  // Disabled/Hidden mutation events
  const observer = new MutationObserver(function(mutations) {
    mutations.forEach(mutation => {
      const property = mutation.attributeName;
      const target   = mutation.target;

      if (property === 'disabled') {
        if (target['disabled'] && typeof object.disable === 'function') object.disable();
        else if (typeof object.enable === 'function') object.enable();
      }
      else if (property === 'hidden') {
        if (target['hidden'] && typeof object.hide === 'function') object.hide();
        else if (typeof object.unhide === 'function') object.unhide();
      }
    });
  });

  observer.observe(el, {attributes: true});
}

// ----------------------------------------------------------------------------
// Private Functions
// ----------------------------------------------------------------------------

/**
 * Ajax (rails-ujs) Event Handlers
 * See: https://edgeguides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
 * Src: ./actionview-x.x.x/lib/assets/compiled/rails-ujs.js
 */
function ajaxHandlers() {
  document.body.addEventListener('ajax:before',   event => ff.startSpinner(event));
  document.body.addEventListener('ajax:complete', event => ff.stopSpinner(event));
  document.body.addEventListener('ajax:error',    event => {
    const detail              = event.detail;
    const [data, status, xhr] = detail;
    const code                = xhr.status;
    const portal              = location.pathname.match(/\/portal\/(employees|donors)/);
    const root                = portal ? portal[0] : '';

    switch (code) {
      case 401: // failed http auth credentials during ajax request (expired session)
      case 403:
      case 404:
        location.href = `${root}/sign_in`;
        break;
      case 500:
        ff.fetch('/debug', {method: 'POST', data: {status: code, url: xhr.responseURL, data: data}});
        window.alert('An error occurred and has been reported, you will be redirected to the dashboard.');
        location.href = `${root}/`;
        break;
      default:
        // ignore everything else, could be user navigating away before request finished or some network issue
        console.warn(`ajax ${code}: ${status}`);
        break;
    }
  });
}

/**
 * Click Handlers
 */
function clickHandlers() {
  document.body.addEventListener('click', function(event) {
    const $target   = event.target;
    const $parent   = $target.parentElement;
    const targetNN  = $target.nodeName.toLowerCase();
    const parentNN  = $parent ? $parent.nodeName.toLowerCase() : null;

    if ((targetNN == 'a' && $target.getAttribute('href') != '#') || (parentNN == 'a' && $parent.getAttribute('href') != '#')) {
      ff.unsavedFormCheck(event);
    }
    else if (targetNN == 'td') {
      if ('checkbox' in $parent.dataset) {
        // toggle checkboxes in TRs with matching data-checkbox values
        const change = new Event('change', {bubbles: true, cancelable: true});

        ff.getAll(`input[data-checkbox="${$parent.dataset.checkbox}"]:not(:disabled)`, $parent).forEach(el => {
          el.checked = !el.checked
          el.dispatchEvent(change); // dispatch a change event with bubbling
        });
      }
      else if (!ff.isEmpty($parent.dataset.url)) {
        // handle URLs associated with a clicked TR
        const confirmed = $parent.dataset.confirm ? window.confirm($parent.dataset.confirm) : true;
        $parent.href    = $parent.dataset.url;

        if (confirmed) {
          if ($parent.dataset.remote) {
            // note: we need to bind tr to Rails.handleRemote so it can be referenced as 'this' within handleRemote
            const remote = Rails.handleRemote.bind($parent);
            remote(event);
          }
          else {
            if ($parent.dataset.target !== undefined) window.open($parent.href);  // open in a new window if any target exists
            else location.href = $parent.href;                                    // replace current location
          }
        }
      }
    }
    else if (targetNN == 'button' || $target.classList.contains('ff-button')) {
      if ($target.dataset.url !== undefined) {
        // AJAX buttons
        if ($target.dataset.remote) {
          if ($target.form && $target.form.getChanged()) {
            ff.stopEvent(event);
            new FastFundModal({title: 'Notice', content: 'Please save your changes before proceeding.'});
          }
          else {
            // XXX don't stop event, let it pass through to Rails.ujs handleConfirm

            /*
            const confirmed = $target.dataset.confirm ? window.confirm($target.dataset.confirm) : true;
            if (confirmed) {
              const remote = Rails.handleRemote.bind($target);
              remote(event);
            }
            */
          }
        }
        else {
          // non-AJAX buttons - open in a new window
          if (ff.unsavedFormCheck(event)) {
            if ($target.dataset.target !== undefined) window.open($target.dataset.url);  // open in a new window if any target exists
            else location.href = $target.dataset.url;                                    // replace current location
          }
        }
      }
    }
  });
}

/**
 * Click Handlers
 */
function keyHandlers() {
  document.body.addEventListener('keydown', function(event) {
    if (event.key == 'Escape') ff.flash(); // clear any flash/status msg on Escape
  });
}

/**
 * Window Handlers
 */
function windowHandlers() {
  const resizeHandler = ff.debounce(()=>{
    // remove the burger-menu class from the sidemenu on all resizes
    ff.get('section.ff-sidemenu')?.classList?.remove('burger-menu');
    // resize any scrollable divs
    ff.initScrollables();
  }, 250);

  window.addEventListener('resize', resizeHandler);
}
